import React from "react";
import Data from '../Data.json';

const SocialLinks = () => {
    return (
      <ul className="flex flew-row">
  
        {/* For each logo in Data.socials... */}
        {Data.socials.map((social) => (
          <div key={social.id}>
            <li className="mr-3">
                <a href={social.href} target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-medium me-2" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}>
                        <path d={social.path_d}/>
                    </svg>
                </a>
              </li>
          </div>
        ))}
  
        {/* Additional logo */}
        <li className="mr-3">
          <a href="https://public.tableau.com/app/profile/will.baird5538/vizzes" target="_blank" rel="noopener noreferrer">
              <img src="tableau_logo_3.svg" alt="Duolingo icon" width="80" height="24" id="duolingo-icon" className="my-1"></img>
          </a>
        </li>

      </ul>
    )
}

export default SocialLinks;
